body {
  background-color: #f5f0f0;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  color: #555555;
  overflow-y: scroll; /* Always show vertical scroll */
}

html {
  overflow-y: scroll; /* Always show vertical scroll */
}

.collapsible-button {
  background: none;
  border: none;
  color: green;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

#pointer-supported{
  cursor: pointer;
}

.framed-artwork {
  border: 4px solid #2c7a3a;; /* Dark brown border, you can change the color */
  box-shadow: 2px 5px 10px #888888; /* Shadow effect for depth */
  padding: 10px; /* Space between the image and the border */
  margin-right: 10px; /* Optional: Space around the outside of the frame */
  background-color: #F5F5F5; /* Light background to simulate matting, change as needed */
  border-radius: 8px; /* This will give the image rounded corners */
}

p,li, body{
  color: rgb(32, 30, 30);
}

div {
    justify-content: center;
    align-items: center;
}
  
table { 
    margin: 0 auto;
}

a {
font-weight: bold;
font-size: 12pt;
color: 978072;
text-decoration:none;
}

.background-image {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('bg5.jpg'); /* Update with the correct path */
  background-size: cover;
  background-attachment: fixed;
  filter: blur(0px); /* Adjust the blur as needed */
  z-index: -1;
}

.landing-content, .main-content {
  position: relative;
  z-index: 1;
}

.landing-content {
  text-align: center;
  padding: 40px 0; /* Reduced top and bottom padding */
  color: white;
}

.profile-image {
  max-width: 300px; /* Adjust to fit your design */
  border-radius: 20%; /* Circular profile image */
  border: 3px solid #675c5c; /* Subtle border color */
  margin-top: 15px;

}
.main-content {
  background: rgba(255, 255, 255, 1);
  padding: 20px;
}

a:hover {
color: #2c7a3a;
text-decoration: none;
font-style: bold;
}

.page_title {
color: #2c7a3a;
font-weight: bold;
font-size: 16pt;
}

.home_title {
  color: #000000;
  font-weight: bold;
  font-size: 16pt;
  font-family: 'Open Sans', sans-serif;
}

.home_subtitle{
    color: #000000;
    font-weight: bold;
    font-size: 12pt;
    margin-bottom: 0px;
  }

.page_subtitle {
color: #2c7a3a;
font-size: 1.2rem;
font-weight: bold;
}

.page_greysubtitle {
color: #000000;
font-weight: bold;
font-size: 12pt;
font-style: italic;
}

.quote {
  color: #000000;
  font-weight: normal;
  font-size: 12pt;
  font-style: italic;

  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  margin-top: 7px;
}

button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  transition-duration: 0.4s;
  border-radius: 40%;
}

.scroll-button{
  margin-bottom: 75px;
}


@media screen and (max-width: 996px) {
  body {
      margin: 0;
      font-size: 16px;
  }

  #FAQFOREST{
    display: none; /* Hide the image on small screens */
  }

  .framed-artwork {
    width: 40%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    margin: 20px auto; /* Center the image and add space above and below */
  }

  div {
    text-align: left; /* Center-align inline and inline-block elements */
  }


  /* Remove any specific left alignment that might be applied elsewhere */
  img.framed-artwork {
    margin-right: -10px;
  }

  table {
      width: 100%; /* Full width */
      border: 0; /* Remove border if any */
  }

  td {
      display: block; /* Stack the content vertically */
      width: 100%;
      box-sizing: border-box;
      padding: 0; /* Remove padding if any */
      text-align: center; /* Center align text */
  }

  .page_title, .page_subtitle, .page_greysubtitle {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center; /* Center align titles */
  }

  p, li {
      font-size: 16px; /* Slightly larger text for readability */
  }

  .main-content, .landing-content {
      padding: 15px; /* Adjust padding for smaller screens */
  }

  .profile-image {
      max-width: 80%; /* Adjust the size to fit mobile screens */
      margin: 0 auto; /* Center the image */
  }

  button {
      font-size: 18px; /* Adjust button font size */
      padding: 10px 15px; /* Adjust button padding */
  }

  .scroll-button {
      width: 50px; 
      margin-top: 20px;
  }

  a {
      font-size: 14px; /* Adjust font size for links */
  }

  /* Additional mobile-specific styles */
  /* ... */
}
